var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"avatar mr-3"},[_c('img',{staticClass:"avatar-50",attrs:{"src":_vm.item.member? _vm.item.member.profile ? _vm.item.member.profile :
      require('@/assets/images/user/default_image.jpg') : require('@/assets/images/user/default_image.jpg'),"alt":_vm.item.profile}}),_c('span',{staticClass:"avatar-status"},[_c('i',{staticClass:"ri-checkbox-blank-circle-fill",class:_vm.item.is_active ? 'text-success' :'text-warning'})])]),_c('div',{staticClass:"chat-sidebar-name"},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.item.name.length > 13 ? _vm.item.name.substring(0 , 13) + '...': _vm.item.name))]),(_vm.item.last_message.chat_type == 'image')?_c('div',[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.item.last_message.caption ? _vm.item.last_message.caption : ''))])]):(_vm.item.last_message.chat_type == 'ptt')?_c('div',{staticClass:"m-0"},[_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.item.last_message.caption ? _vm.item.last_message.caption : ''))])]):(_vm.item.last_message.chat_type == 'video')?_c('div',[_vm._m(2),_c('p',[_vm._v(_vm._s(_vm.item.last_message.caption ? _vm.item.last_message.caption : ''))])]):(_vm.item.last_message.chat_type == 'location')?_c('div',[_vm._m(3)]):(_vm.item.last_message.chat_type == 'call_log')?_c('div',[(_vm.item.last_message.message == '[Call - miss]')?_c('p',{staticClass:"font-size-16 m-0"},[_c('i',{staticClass:"las la-phone-slash text-danger"}),_vm._v(" Missed Call")]):(_vm.item.last_message.message == '[Call - miss_video]')?_c('p',{staticClass:"font-size-16 m-0"},[_c('i',{staticClass:"las la-video text-danger"}),_vm._v(" Missed Video Call")]):_vm._e()]):(_vm.item.last_message.chat_type == 'vcard')?_c('div',[_vm._m(4)]):_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.item.last_message.message.length > 20 ? _vm.item.last_message.message.substring(0 , 20) + '...': _vm.item.last_message.message))]),_c('p',{staticClass:"font-size-12 font-weight-light text-gray text-right mb-0"},[_vm._v(_vm._s(_vm.timeConvertWhatsapp(_vm.item.last_message.created_at)))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"m-0 text-success"},[_c('i',{staticClass:"las la-image"}),_vm._v(" Image")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"m-0 text-success"},[_c('i',{staticClass:"las la-microphone-alt"}),_vm._v(" Voice")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"m-0 text-success"},[_c('i',{staticClass:"las la-video"}),_vm._v(" Video")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-size-16 m-0 text-danger"},[_c('i',{staticClass:"las la-map-marked-alt"}),_vm._v(" Location")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-size-16 m-0 text-success"},[_c('i',{staticClass:"las la-id-card"}),_vm._v(" Card")])
}]

export { render, staticRenderFns }